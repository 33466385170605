import { Show, splitProps } from "solid-js";
import { SingleInputProps } from "./model";

export function SingleInput(props: SingleInputProps) {
  const [local, others] = splitProps(props, ["events", "elements", "options"]);
  //  ${len <= 4 ? "flex gap-5px":""} ${len == 1 && "w-826px"}
  return (
    <Show
      when={props.options.editable}
      fallback={
        <input
          {...others}
          name={props.elements.input?.toString()}
          class={`w-full h-41px bg-inherit text-14px font-500 p-10px overflow-hidden leading-43px`}
          value={props.elements.input}
          readOnly
        />
      }
    >
      <input
        {...others}
        name={props.elements.input?.toString()}
        class={`w-full h-41px text-14px text#n-200 border-dashed border-2 border#n-200 font-500 p-10px overflow-hidden placeholder:text#n-200 leading-43px`}
        value={props.elements.input}
        onInput={props.events.onChange}
      />
    </Show>
  );
}
